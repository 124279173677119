import { Box, Link, Stack, Image, SimpleGrid, Center, Flex } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import WhiteLogo from '../images/logo-white.svg';
import FacebookLogo from '../images/facebook.svg';
import InstagramLogo from '../images/instagram.svg';
import LinkedinLogo from '../images/linkedin.svg';

const Footer = () => {
  return <Box backgroundColor="#281727" px={["2rem", "2rem", "4rem"]}>
    <Flex color="white" direction={["column", null, null, "row"]}>
      <Center py="4rem">
        <Link to="/" as={GatsbyLink} isExternal>
          <Image src={WhiteLogo} height="80px" />
        </Link>
      </Center>
      <Center flex={1} px="10%" py="2rem">
        <SimpleGrid columns={3} spacing={8} width="100%" maxW={800} textAlign="center">
          <Link to="/" as={GatsbyLink}>Home</Link>
          <Link to="/product" as={GatsbyLink}>Product</Link>
          <Link to="/contact" as={GatsbyLink}>Contact</Link>
          <Link to="/about" as={GatsbyLink}>About</Link>
          <Link to="https://medium.com/makeripples" as={GatsbyLink}>Blog</Link>
          <Link to="/help" as={GatsbyLink}>Help</Link>
        </SimpleGrid>
      </Center>
      <Center py="2rem">
        <Stack direction="row" spacing={8}>
          <Link to="https://www.linkedin.com/company/makeripplesnz/" as={GatsbyLink} isExternal>
            <Image src={LinkedinLogo} />
          </Link>
          <Link to="https://www.instagram.com/make_ripples/" as={GatsbyLink} isExternal>
            <Image src={InstagramLogo} />
          </Link>
          <Link to="https://www.facebook.com/makeripples/" as={GatsbyLink} isExternal>
            <Image src={FacebookLogo} />
          </Link>
        </Stack>
      </Center>
    </Flex>
  </Box>
}

export default Footer;
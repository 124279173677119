import { Box } from '@chakra-ui/react';
import React from 'react';
import Footer from './footer';
import Header from './header';

const Layout = ({children, activePage, hideHeader = false}) => {
  return <Box backgroundColor="#FAF7FA" overflow="hidden">
    {!hideHeader && <Header activePage={activePage} />}
    {children}
    <Footer />
  </Box>
}

export default Layout;
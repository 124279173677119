import { Box, Link, Flex, Text, Button, Image } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import Hamburger from 'hamburger-react';
import React, { useState } from 'react';
import Logo from '../images/logo.svg';


const AnnouncementBanner = () => <Box backgroundColor="#0F9D92" color="white" textAlign="center" p="0.5rem">
  We're building a free version of makeripples!
  <Link to="/ripple-maker/" as={GatsbyLink} ml="0.5rem">
    <Button whiteSpace="break-spaces" variant="outline" size="xs" borderRadius="25px">
      Learn more
    </Button>
  </Link>
</Box>

const Header = ({ activePage }) => {
  const [show, setShow] = useState(false);
  const toggleMenu = () => setShow(!show)

  const NavLink = ({ children, to = "/", isLast = false, isExternal = false, ...rest }) => {
    return <Text
      mb={{ base: isLast ? 0 : 8, md: 0 }}
      mr={{ base: 0, md: isLast ? 0 : 8 }}
      display="block"
      borderBottomColor={activePage === to ? "brand.primary" : "transparent"}
      borderBottomWidth="5px"
      _hover={{
        borderBottomColor: "brand.primary"
      }}
      {...rest}
    >
      <Link to={to} as={GatsbyLink} isExternal={isExternal} px={["0.4rem", null, null, "0.5rem", "1rem"]} py="0.5rem" display="block">{children}</Link>
    </Text>
  }

  return <Box as="header" pos="absolute" width="100%" backgroundColor={{base: show ? 'white' : 'initial', lg: 'initial'}} zIndex={100} boxShadow={{base: show ? '0px 5px 5px 0px rgba(0,0,0,0.2)' : 'unset', lg: 'unset'}}>
    <AnnouncementBanner />
    <Box px={["2rem", "2rem", "4rem"]} pt="0.5rem">
      <Flex as="nav" justify="space-between" wrap="wrap">
        <Flex align="center">
          <Link to="/" as={GatsbyLink} isExternal>
            <Image src={Logo} height="80px" />
          </Link>
        </Flex>
        <Box display={{ base: "block", lg: "none" }} onClick={toggleMenu}>
          <Hamburger toggled={show} toggle={toggleMenu} />
        </Box>
        <Box
          display={{ base: show ? "block" : "none", lg: "block" }}
          flexBasis={{ base: "100%", lg: "auto" }}
        >
          <Flex
            align="center"
            justify={["center", null, null, "flex-end", "flex-end"]}
            direction={["column", null, "row"]}
            pt={[4, 4, 0, 0]}
          >
            <NavLink to="/">Home</NavLink>
            <NavLink to="/about">About </NavLink>
            <NavLink to="/product">Product </NavLink>
            <NavLink to="/contact">Contact </NavLink>
            <NavLink to="https://app.makeripples.nz/account/signup?r=%2Forganiser" isExternal borderBottom="unset">
              <Button size="md" colorScheme="brand">
                Get Started
              </Button>
            </NavLink>
            <NavLink to="https://app.makeripples.nz/account/signin?r=%2Forganiser" isLast isExternal>Sign in </NavLink>
          </Flex>
        </Box>
      </Flex>
    </Box>
  </Box>
}

export default Header;